<template>
  <div class="mt-3">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Generali" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_CNAM_value.label"
                        vid="attribute_CNAM_value"
                        :label="beForm[rep].attribute_CNAM_value.label"
                        v-model="form[rep].attribute_CNAM_value"
                        placeholder="Inserisci una ragione sociale"
                        :rules="{ required: true }"
                      />
                    </div>

                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_CREG_value.label"
                        vid="attribute_CREG_value"
                        :label="beForm[rep].attribute_CREG_value.label"
                        v-model="form[rep].attribute_CREG_value"
                        placeholder="Inserisci una partita IVA"
                        :rules="{ required: true }"
                      />
                    </div>

                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_NINO_value.label"
                        vid="attribute_NINO_value"
                        :label="beForm[rep].attribute_NINO_value.label"
                        v-model="form[rep].attribute_NINO_value"
                        textcase="upper"
                        placeholder="Inserisci un codice fiscale"
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-md-3">
                      <base-select
                        name="provincia"
                        label="Provincia"
                        v-model="form.province"
                        :options="opt_provinces"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        @select="onSelectProvince"
                        @remove="onRemoveProvince"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="col-md-3">
                      <base-select
                        :name="beForm[cor].attribute_CITY_value.label"
                        vid="attribute_CITY_value"
                        :label="beForm[cor].attribute_CITY_value.label"
                        v-model="form[cor].attribute_CITY_value"
                        :options="opt_councils"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-textarea
                        :name="beForm[cor].attribute_ADDR_value.label"
                        vid="attribute_ADDR_value"
                        :label="beForm[cor].attribute_ADDR_value.label"
                        v-model="form[cor].attribute_ADDR_value"
                        placeholder="Inserisci un indirizzo"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="col-md-3">
                      <base-input
                        :name="beForm[cor].attribute_ZIPC_value.label"
                        vid="attribute_ZIPC_value"
                        :label="beForm[cor].attribute_ZIPC_value.label"
                        v-model="form[cor].attribute_ZIPC_value"
                        maxlength="5"
                        placeholder="Inserisci un CAP"
                        :rules="{ required: true }"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-md-3">
                      <base-input
                        :name="beForm[addr_book].attribute_PHON_value.label"
                        vid="attribute_PHON_value"
                        :label="beForm[addr_book].attribute_PHON_value.label"
                        v-model="form[addr_book].attribute_PHON_value"
                        type="tel"
                        placeholder="Inserisci un numero di telefono"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[addr_book].attribute_FAX_value.label"
                        vid="attribute_FAX_value"
                        :label="beForm[addr_book].attribute_FAX_value.label"
                        v-model="form[addr_book].attribute_FAX_value"
                        placeholder="Inserisci un fax"
                      />
                    </div>
                    <div class="col-md-3">
                      <base-input
                        :name="beForm[addr_book].attribute_WEB_value.label"
                        vid="attribute_WEB_value"
                        :label="beForm[addr_book].attribute_WEB_value.label"
                        v-model="form[addr_book].attribute_WEB_value"
                        placeholder="Inserisci un sito internet"
                      />
                    </div>
                    <div class="col-md-3">
                      <base-input
                        :name="beForm[addr_book].attribute_MAIL_value.label"
                        vid="attribute_MAIL_value"
                        :label="beForm[addr_book].attribute_MAIL_value.label"
                        v-model="form[addr_book].attribute_MAIL_value"
                        placeholder="Inserisci una mail"
                        :rules="{ required: true, email: true }"
                      />
                    </div>
                    <div class="col-md-3">
                      <base-textarea
                        :name="beForm[addr_book].attribute_SIGNMAIL_value.label"
                        vid="attribute_SIGNMAIL_value"
                        :label="
                          beForm[addr_book].attribute_SIGNMAIL_value.label
                        "
                        v-model="form[addr_book].attribute_SIGNMAIL_value"
                        placeholder="Inserisci una firma email"
                      />
                    </div>
                    <div class="col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_ROBR_value.label"
                        vid="attribute_ROBR_value"
                        :label="beForm[rep].attribute_ROBR_value.label"
                        v-model="form[rep].attribute_ROBR_value"
                        placeholder="Inserisci Iscrizione Rui"
                      />
                    </div>
                    <div class="col-md-3">
                      <base-input
                        :name="beForm[addr_book].attribute_SMSFROM_value.label"
                        vid="attribute_SMSFROM_value"
                        :label="beForm[addr_book].attribute_SMSFROM_value.label"
                        v-model="form[addr_book].attribute_SMSFROM_value"
                        placeholder="Inserisci un mittente sms"
                        maxlength="11"
                      />
                    </div>
                    <div class="col-md-3">
                      <base-input
                        :name="beForm[addr_book].attribute_MAILFROM_value.label"
                        vid="attribute_MAILFROM_value"
                        :label="
                          beForm[addr_book].attribute_MAILFROM_value.label
                        "
                        v-model="form[addr_book].attribute_MAILFROM_value"
                        placeholder="Inserisci un mittente mail"
                        maxlength="100"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Canale di comunicazione"
                header-tag="header"
                class="mt-2"
              >
                <b-card-text>
                  <b-row>
                    <div class="col-md-3">
                      <base-select
                        :name="beForm[rep].attribute_DCHAN_value.label"
                        vid="attribute_DCHAN_value"
                        :label="beForm[rep].attribute_DCHAN_value.label"
                        v-model="form[rep].attribute_DCHAN_value"
                        :rules="{ required: true }"
                        :options="beForm[rep].attribute_DCHAN_value.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseTextarea from "@/components/form/BaseTextarea";
import ShortcutMixin from "@/mixins/ShortcutMixin";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "agency_setup",
      id: this.$route.params.id,
      item: this.$route.params.item,
      opt_councils: [],
      opt_provinces: [],
      address_book_id: null,
      correspondence_id: null,
      defaultCountryName: "Italia",
      form: {
        province: null,
        agency_setup: {
          code: null,
          title: null,
          attribute_ROBR_value: null,
          attribute_NINO_value: null,
          attribute_CREG_value: null,
          attribute_CNAM_value: null,
        },
        address_book: {
          status_addressbook: 0,
          attribute_CELL_value: null,
          attribute_FAX_value: null,
          attribute_MAIL_value: null,
          attribute_PEC_value: null,
          attribute_PHON_value: null,
          attribute_WEB_value: null,
          attribute_SMSFROM_value: null,
          attribute_PECFROM_value: null,
          attribute_SIGNMAIL_value: null,
          agency_setup: {},
          agency_contact: {},
        },
        correspondence: {
          status_correspondence: 0,
          attribute_ADDR_value: null,
          attribute_CITY_value: null,
          attribute_CITY2_value: null,
          attribute_NOR_value: null,
          attribute_NAME2_value: null,
          attribute_ZIPC_value: null,
          agency_setup: {},
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    BaseTextarea,
  },
  methods: {
    onSubmit() {
      debugger;
      this.isLoading = true;

      let promises = [];

      // Update agency_setup form
      promises.push(this.update(this.rep, this.id));

      // Update or create address_book/correspondence
      promises.push(this.storeSection(this.address_book_id, this.addr_book));
      promises.push(this.storeSection(this.correspondence_id, this.cor));

      // Update DCHAN
      if (this.form[this.rep].attribute_DCHAN_value)
        this.$store.state.auth.settings.attribute_DCHAN_value =
          this.form[this.rep].attribute_DCHAN_value;

      Promise.all(promises)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione completata: Dati di agenzia aggiornati`,
          });
          this.shortcut("agency_setups.index", null, null);
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
          this.isLoading = false;
        });
    },
    storeSection(id, repository) {
      if (id) {
        return this.update(repository, id);
      } else {
        return this.store(repository);
      }
    },
    onSelectProvince(selected) {
      this.opt_councils = this.councils()(selected);
      this.form[this.cor].attribute_CITY_value = null;
    },
    onRemoveProvince() {
      this.opt_councils = [];
      this.form[this.cor].attribute_CITY_value = null;
    },
    initCorrespondenceForm() {
      const defaultCountryId = this.countries().find(
        (country) => country.text == this.defaultCountryName
      ).value;
      this.form[this.cor].attribute_NOR_value = defaultCountryId;
      this.opt_provinces = this.provinces()(defaultCountryId);
      if (this.correspondence_id) {
        const council = this.$store.state.auth.councils.find(
          (council) => council.id == this.form[this.cor].attribute_CITY_value
        );
        if (council) {
          this.opt_councils = this.councils()().filter(
            (council) =>
              council.value == this.form[this.cor].attribute_CITY_value
          );
          this.form.province = council.province_id;
        }
      }
    },
    setFormRelations() {
      this.form[this.addr_book].agency_setup = { [this.id]: {} };
      this.form[this.cor].agency_setup = { [this.id]: {} };
    },
    fetchAddressBook() {
      if (this.item.address_books && this.item.address_books.length) {
        const found = this.item.address_books.find(
          (address) => address.status_addressbook.value === 0
        );
        if (found) {
          this.address_book_id = found.id;
          return this.fetchEditForm(this.addr_book, this.address_book_id);
        }
      } else {
        return this.fetchCreateForm(this.addr_book);
      }
    },
    fetchCorrespondence() {
      console.log("CoR", this.item);
      if (this.item.correspondences && this.item.correspondences.length) {
        console.log("CoR", this.item);

        const found = this.item.correspondences.find(
          (e) => e.status_correspondence.value === 0
        );
        if (found) {
          this.correspondence_id = found.id;
          return this.fetchEditForm(this.cor, this.correspondence_id);
        }
      } else {
        return this.fetchCreateForm(this.cor);
      }
    },
    fetch() {
      this.isLoading = true;

      // Fetch edit agency_setup form
      const as = this.fetchEditForm(this.rep, this.id);

      // Check agency_setup address_book and correspondence
      const ab = this.fetchAddressBook();
      const cr = this.fetchCorrespondence();

      Promise.all([as, ab, cr])
        .then(() => {
          this.initMandatoryFields("agency_setup");
          this.initCorrespondenceForm();
          this.setFormRelations();
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
          this.isLoading = false;
        });
    },
    ...mapGetters("auth", ["provinces", "councils", "countries"]),
  },
  created() {
    this.fetch();
  },
  computed: {
    rep() {
      return this.repository;
    },
    cor() {
      return "correspondence";
    },
    addr_book() {
      return "address_book";
    },
    agency_contact() {
      return "agency_contact";
    },
  },
};
</script>
